<template>
  <v-container fluid>
    <v-row>
<!--       <v-col cols=3>
        <v-card>
          <v-card-text>
            <div>Jobs</div>
            <p class="display-1 text--primary">
              Saved Views
            </p>
            <v-list>
              <v-list-item
                v-for="(view, viewKey) in savedViews"
                :key="viewKey"
                :to="view.to"
                color="primary"
                exact
                link
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ view.name }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>mdi-menu-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <DataTable
          :cols="cols"
          :filter-keys="filterKeys"
          :request-params="{ with: ['booking.customer.account.parent', 'service', 'asset', 'sundrySums'], paid: true }"
          default-order-by="created_at"
          path="jobs"
        >
          <template v-slot:item.ref="{ item }">
            <v-chip
              :to="{ name: 'bookings.show', params: { bookingId: item.booking_id } }"
              small
            >
              {{ item.ref || ' - ' }}
            </v-chip>
          </template>
          <template v-slot:item.loc="{ item }">
            <strong>{{ item.location_prefix_label }}</strong>{{ item.location }}
          </template>
          <template v-slot:item.service="{ item: bike }">
            {{ bike.service.name }}
          </template>
          <template v-slot:item.customer_name="{ item: bike }">
            <v-chip
              :to="{ name: 'customers.show', params: { customerId: bike.booking.customer_id } }"
              outlined
              pill
              small
            >
              <v-icon
                small
                left
              >
                mdi-account-circle
              </v-icon>        
              {{ bike.booking.customer_name }}
            </v-chip>
          </template>
          <template v-slot:item.account="{ item }">
            <v-chip
              v-if="item.booking.customer.account"
              outlined
              pill
              small
            >
              <v-icon
                small
                left
              >
                mdi-account-circle
              </v-icon>        
              {{ item.booking.customer.account.name }}
            </v-chip>
            <span v-else>-</span>
          </template>
          <template v-slot:item.asset="{ item: bike }">
            {{ bike.asset ? bike.asset.number : '-' }}
          </template>
          <template v-slot:item.sundries_total="{ item: bike }">
            {{ $currency(bike.sundries_total) }}
          </template>
          <template v-slot:item.total="{ item: bike }">
            {{ $currency(bike.total) }}
          </template>
          <template v-slot:item.collection_date="{ item: bike }">
            <strike v-if="bike.rearranged_collection_date">{{ $momentDate(bike.booking.collection_date) }}</strike>
            <span v-else>{{ $momentDate(bike.booking.collection_date) }}</span>
            <strong v-if="bike.rearranged_collection_date"> {{ $momentDate(bike.rearranged_collection_date) }}</strong>
          </template>
          <template v-slot:item.planned_service_date="{ item: bike }">
            <span v-if="bike.planned_service_date"> {{ $momentDate(bike.planned_service_date) }}</span>
          </template>
          <template v-slot:item.return_date="{ item: bike }">
            <strike v-if="bike.rearranged_return_date">{{ $momentDate(bike.booking.return_date) }}</strike>
            <span v-else>{{ $momentDate(bike.booking.return_date) }}</span>
            <strong v-if="bike.rearranged_return_date"> {{ $momentDate(bike.rearranged_return_date) }}</strong>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'

export default {
  name: 'Services',

  components: {
    DataTable
  },

  data () {
    return {
      collectionStates: {},
      workshopStates: {},
      returnStates: {},
      services: [],
      jobTypes: {},
      cols: [
        { text: 'Ref', value: 'ref' },
        { text: 'Loc', value: 'loc' },
        { text: 'Type', value: 'job_type_label' },
        { text: 'Customer', value: 'customer_name' },
        { text: 'Account', value: 'account' },
        { text: 'Asset', value: 'asset' },
        { text: 'Service Booked', value: 'service' },
        { text: 'Collection Status', value: 'collection_status_label' },
        { text: 'Workhop Status', value: 'workshop_status_label' },
        { text: 'Return Status', value: 'return_status_label' },
        { text: 'Bicycle', value: 'name' },
        { text: 'Sundries', value: 'sundries_total' },
        { text: 'Service', value: 'total' },
        { text: 'Collection', value: 'collection_date' },
        { text: 'TBO Date', value: 'planned_service_date' },
        { text: 'Return', value: 'return_date' }
      ]
    }
  },

  mounted () {
    this.getServices()
    this.getJobTypes()
    this.getJobCollectionStates()
    this.getJobWorkshopStates()
    this.getJobReturnStates()
  },

  computed: {
    savedViews () {
      return [
        {
          name: 'Awaiting Parts',
          to: {
            name: 'services',
            query: {
              filters: JSON.stringify([
                {
                  operator: { key: 'is', label: 'is' },
                  key: { key: 'workshop_status', type: 'Select', label: 'Workshop Status' },
                  value: { label: 'Awaiting Parts', key: 'STATUS_AWAITING_PARTS' }
                }
              ])
            }
          }
        }
      ]
    },

    availableServices () {
      var services = []
      this.services.forEach(service => {
        services.push({
          label: `${service.name} (${service.sku})`,
          value: service.sku
        })
      })
      return services
    },

    availableJobTypes () {
      var jobTypes = []
      Object.keys(this.jobTypes).forEach(key => {
        jobTypes.push({
          label: this.jobTypes[key],
          value: key
        })
      })
      return jobTypes
    },

    availableCollectionsStates () {
      var collectionStates = []
      Object.keys(this.collectionStates).forEach(key => {
        collectionStates.push({
          label: this.collectionStates[key],
          value: key
        })
      })
      return collectionStates
    },

    availableWorkshopStates () {
      var workshopStates = []
      Object.keys(this.workshopStates).forEach(key => {
        workshopStates.push({
          label: this.workshopStates[key],
          value: key
        })
      })
      return workshopStates
    },

    availableReturnStates () {
      var returnStates = []
      Object.keys(this.returnStates).forEach(key => {
        returnStates.push({
          label: this.returnStates[key],
          value: key
        })
      })
      return returnStates
    },

    filterKeys () {
      return [
        { key: 'ref', 'label': 'Ref', type: 'String' },
        { key: 'type', 'label': 'Type', type: 'Select', values: this.availableJobTypes},
        { key: 'service', 'label': 'Service Booked', type: 'Select', values: this.availableServices},
        { key: 'collection_status', 'label': 'Collection Status', type: 'Select', values: this.availableCollectionsStates},
        { key: 'workshop_status', 'label': 'Workshop Status', type: 'Select', values: this.availableWorkshopStates},
        { key: 'return_status', 'label': 'Return Status', type: 'Select', values: this.availableReturnStates},
        { key: 'collection_date', 'label': 'Collection Date', type: 'Date' },
        { key: 'return_date', 'label': 'Return Date', type: 'Date' },
        { key: 'created_at', label: 'Created At', type: 'Date' },
        { key: 'planned_service_date', 'label': 'TBO Date', type: 'Date' },
        { key: 'account', 'label': 'Account', type: 'Lookup', lookup: { label: 'Accounts', path: 'accounts', return_value: 'id', return_label: 'name'  } }
      ]
    }
  },

  methods: {
    getServices () {
      this.$api.get({
        path: 'services'
      })
        .then(({ data: services }) => {
          this.services = services
        })
    },

    getJobTypes () {
      this.$api.get({
        path: 'job-types'
      })
        .then(jobTypes => {
          this.jobTypes = jobTypes
        })
    },

    getJobCollectionStates () {
      this.$api.get({
        path: 'job-collection-states'
      })
        .then(states => {
          this.collectionStates = states
        })
    },

    getJobWorkshopStates () {
      this.$api.get({
        path: 'job-workshop-states'
      })
        .then(states => {
          this.workshopStates = states
        })
    },

    getJobReturnStates () {
      this.$api.get({
        path: 'job-return-states'
      })
        .then(states => {
          this.returnStates = states
        })
    }
  }
}
</script>

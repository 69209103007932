var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"cols":_vm.cols,"filter-keys":_vm.filterKeys,"request-params":{ with: ['booking.customer.account.parent', 'service', 'asset', 'sundrySums'], paid: true },"default-order-by":"created_at","path":"jobs"},scopedSlots:_vm._u([{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"to":{ name: 'bookings.show', params: { bookingId: item.booking_id } },"small":""}},[_vm._v(" "+_vm._s(item.ref || ' - ')+" ")])]}},{key:"item.loc",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.location_prefix_label))]),_vm._v(_vm._s(item.location)+" ")]}},{key:"item.service",fn:function(ref){
var bike = ref.item;
return [_vm._v(" "+_vm._s(bike.service.name)+" ")]}},{key:"item.customer_name",fn:function(ref){
var bike = ref.item;
return [_c('v-chip',{attrs:{"to":{ name: 'customers.show', params: { customerId: bike.booking.customer_id } },"outlined":"","pill":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(bike.booking.customer_name)+" ")],1)]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [(item.booking.customer.account)?_c('v-chip',{attrs:{"outlined":"","pill":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(item.booking.customer.account.name)+" ")],1):_c('span',[_vm._v("-")])]}},{key:"item.asset",fn:function(ref){
var bike = ref.item;
return [_vm._v(" "+_vm._s(bike.asset ? bike.asset.number : '-')+" ")]}},{key:"item.sundries_total",fn:function(ref){
var bike = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(bike.sundries_total))+" ")]}},{key:"item.total",fn:function(ref){
var bike = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(bike.total))+" ")]}},{key:"item.collection_date",fn:function(ref){
var bike = ref.item;
return [(bike.rearranged_collection_date)?_c('strike',[_vm._v(_vm._s(_vm.$momentDate(bike.booking.collection_date)))]):_c('span',[_vm._v(_vm._s(_vm.$momentDate(bike.booking.collection_date)))]),(bike.rearranged_collection_date)?_c('strong',[_vm._v(" "+_vm._s(_vm.$momentDate(bike.rearranged_collection_date)))]):_vm._e()]}},{key:"item.planned_service_date",fn:function(ref){
var bike = ref.item;
return [(bike.planned_service_date)?_c('span',[_vm._v(" "+_vm._s(_vm.$momentDate(bike.planned_service_date)))]):_vm._e()]}},{key:"item.return_date",fn:function(ref){
var bike = ref.item;
return [(bike.rearranged_return_date)?_c('strike',[_vm._v(_vm._s(_vm.$momentDate(bike.booking.return_date)))]):_c('span',[_vm._v(_vm._s(_vm.$momentDate(bike.booking.return_date)))]),(bike.rearranged_return_date)?_c('strong',[_vm._v(" "+_vm._s(_vm.$momentDate(bike.rearranged_return_date)))]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }